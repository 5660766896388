<template>
  <div class="row cards" id="home-page">
    <div class="col-md-4 card-container" v-for="service in aiServices" :key="service.tag">
      <b-card :title="service.title" :img-src="service.imgSrc" :img-alt="service.imgAlt" img-top class="mb-2">
        <b-card-text>{{ service.textContent }}</b-card-text>
        <b-button :to="service.link" variant="primary" class="card-btn">
          <b-icon icon="arrow-right" font-scale="1.2"></b-icon>
          iniciar
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aiServices: [
        {
          title: "Criar artigo para blog",
          imgSrc: require("@/assets/service-1.jpg"),
          imgAlt: "Criar artigo para blog",
          textContent:
            "Gere hashtags, títulos, descrições para blogs, palavras-chave e muito mais, basta digitar o assunto ou tags para começar.",
          link: "/blog-writing",
        },
        {
          title: "E-mail Marketing",
          imgSrc: require("@/assets/service-2.jpg"),
          imgAlt: "Cold Email Template",
          textContent:
            "Com essa ferramenta inovadora, você pode gerar ideias criativas e personalizadas em tempo real, garantindo que sua estratégia de e-mail marketing se destaque entre as demais. Não perca mais tempo tentando encontrar ideias por conta própria - adote a tecnologia AI e comece a colher os resultados imediatamente.",
          link: "/cold-email",
        },
        {
          title: "Postagens profissionais com IA para suas mídias sociais",
          imgSrc: require("@/assets/service-3.jpg"),
          imgAlt: "AI Tweet Writing Tool ",
          textContent:
            "Chega de bloqueios criativos na hora de criar postagens para suas redes sociais (Twitter, Facebook, Instagram, LinkedIn)! Com essa ferramenta incrível, você pode gerar ideias de postagens exclusivas e criativas, além de hashtags relevantes, que vão impulsionar a interação com seus seguidores. Acabe com o estresse e a frustração de tentar criar postagens por conta própria - adote essa tecnologia de ponta e comece a criar conteúdo de qualidade em um piscar de olhos. Crie infinitas ideias de postagens e deixe sua marca nas redes sociais. Experimente agora mesmo e eleve sua presença on-line ao próximo nível!",
          link: "/social-media-post",
        },
      ],
    };
  },
};
</script>

<style>
.cards {
  margin-top: 50px;
}

.cards .card-title {
  text-transform: capitalize;
}

.cards .card-container {
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;
}

.cards .card-container .card {
  padding-bottom: 45px;
}

.cards .card-container .card-btn {
  position: absolute;
  bottom: 20px;
}

.sub-title {
  text-transform: capitalize;
}
</style>