<template>
  <div class="navigation">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-container>
        <b-navbar-brand href="https://oriondma.com" exact-active-class="active">
          <h2 class="mt-1">
            ORION DMA
          </h2>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="mx-auto">
            <b-nav-item to="/" exact-active-class="active">
              Home
            </b-nav-item>
            <b-nav-item to="/blog-writing" exact-active-class="active">
              Blog Writing
            </b-nav-item>
            <b-nav-item to="/cold-email" exact-active-class="active"
              >Cold Emails</b-nav-item
            >
            <b-nav-item to="/social-media-post" exact-active-class="active"
              >Social Media</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoSrc: require("@/assets/logo-2.png")
    };
  },
};
</script>

<style scoped>
.navbar-dark .navbar-nav .nav-item {
  padding: 0 10px;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255);
  padding: 0;
}
.navbar-dark .navbar-nav .nav-link.active {
  position: relative;
}
.navbar-dark .navbar-nav .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #fff;
}
.navbar-dark #logo-img{
  width:300px
}
</style>
