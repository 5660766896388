<template>
  <div id="app">
    <!-- overlay -->
    <div class="overlay" v-if="isLoading">
      <spinner />
      <span class="overlay-text"> Por favor, aguarde ... </span>
    </div>

    <!-- navigation bar -->
    <Navigation />

    <!-- content -->
    <b-container class="mt-5">
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
// import navbar component
import Navigation from "./components/NavBar.vue";
// impor a loader from loader component
import spinner from "./components/vue-loader-circle-9.vue";

export default {
  name: "App",
  components: {
    Navigation,
    spinner,
  },
  data() {
    return {
      isLoading: true,
    };
  },

  created() {
    this.loadingPage(1000);
  },
  methods: {
    loadingPage(timeout) {
      // hide loading pag before timout
      setTimeout(() => {
        this.isLoading = false;
      }, timeout);
    },
  },
};

</script>

<style>
/* import cairo font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
  padding-bottom: 60px !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(1, 8, 29);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay .overlay-text {
  color: #fff;
  font-size: 30px;
  display: block;
  margin-top: 30px;
}
</style>
