// import vue router
import Vue from "vue";
import VueRouter from "vue-router";

// components
import home_page from "../views/home-page.vue";
import blog_writing from "../views/blog-writing.vue";
import cold_email from "../views/cold-email.vue";
import social_media_post from "../views/social-media-post.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: home_page,
  },
  {
    path: "/blog-writing",
    name: "blogWriting",
    component: blog_writing,
  },
  {
    path: "/cold-email",
    name: "coldEmail",
    component: cold_email,
  },
  {
    path: "/social-media-post",
    name: "socialMediaPosts",
    component: social_media_post,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes, // short for `routes: routes`
});

Vue.use(VueRouter);

export default router;
