<template>
  <!-- blog page -->
  <div class="blog-page">
    <!-- blog title -->
    <div class="blog-title d-flex">
      <div class="icon mt-4 mr-1">
        <b-icon icon="envelope" scale="3" variant="info"></b-icon>
      </div>
      <div class="title-content ml-4">
        <h1 class="title">
          Crie um modelo de e-mail Marketing com IA
        </h1>
        <div class="sub-title">
          <p>
            Com a ajuda da nossa avançada tecnologia de Inteligência Artificial, o Chatbot IA entende as suas necessidades
            e fornece os e-mails de marketing mais relevantes com base em sua consulta. Além disso, o Chatbot IA também é
            capaz de automatizar as respostas de e-mail para seus clientes e leads, economizando tempo valioso e
            aumentando a eficiência do seu negócio. Não perca mais tempo vasculhando inúmeras mensagens de e-mail,
            experimente agora mesmo o Chatbot IA e alcance o sucesso em sua estratégia de marketing!
          </p>
        </div>
      </div>
    </div>
    <!-- blog form -->
    <div class="blog-form">
      <b-form @submit="onSubmit" v-if="show">
        <b-form-group id="input-group-1" label="Qual é o assunto do e-mail?  " label-for="input-1"
          description="Insira o máximo de informações possíveis, para obter o resultado mais preciso.">
          <b-form-input id="input-1" v-model="inputValue" type="text" :disabled="isDisabled"
            placeholder="Por exemplo: marketing para um produto esportivo, venda de roupas" required></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="info" :class="{ 'disabled': !inputValue || isDisabled }"
          :disabled="inputValue == null || isDisabled">
          <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': inProgress }"></b-icon>
          Get Ai Suggestions
        </b-button>
      </b-form>

      <!-- blog form result -->
      <div class="blog-form-result mt-5">
        <div class="card-deck">
          <div class="card">
            <h4 :class="{ 'card-header': true, 'bg-black': !isDone, 'bg-info text-white': isDone }">{{ card.header }}</h4>
            <div class="card-body p-0">
              <b-form-textarea id="textarea" v-model="card.description" placeholder=".... Aguardando resposta" rows="2"
                max-rows="15" disabled></b-form-textarea>

              <button class="btn btn-info btn-sm mt-3 mx-2 mb-3" v-if="isDone" @click="copyTextToClipboard">
                <b-icon icon="file-earmark-post"></b-icon>
                Copiar para área de transferência
              </button>
            </div>
            <div class="card-footer" v-if="inProgress">
              Por favor espere
              <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': inProgress }"></b-icon>
            </div>
          </div>
        </div>

        <!-- errors -->
        <b-alert :show="msg.hasError" dismissible variant="info" class="mt-3">
          <p>{{ msg.errorMessage }}</p>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      show: true,
      isDisabled: false,
      inputValue: null,
      inProgress: false,
      isDone: false,
      msg: {
        waitResponse: ".... Aguardando resposta",
        initHeader: "A resposta da IA será mostrada aqui.",
        doneResponse: "Sugestões de e-mail pela IA : ",
        errorMessage: "",
        hasError: false,
      },
      card: {
        header: "A resposta da IA será mostrada aqui.",
        title: null,
        description: "Você ainda não pesquisou ^-^",
      },
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      // if input has value
      if (this.inputValue) {
        // reset data values
        this.resetFields(true);

        // create new openAi request
        const client = this.$axios.create({
          headers: {
            Authorization: `Bearer ${this.API_KEY}`,
          },
        });

        const params = {
          model: "text-davinci-003",
          prompt: `Escreva um grande modelo de e-mail de marketing profissional sobre : ${this.inputValue}`,
          temperature: 1,
          max_tokens: 2048,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              this.card.header = `${this.msg.doneResponse} ${this.inputValue}`;
              this.card.title = this.inputValue;

              let email = result.data.choices[0].text;
              email = email.replace(/^\n+/, "");

              this.card.description = email;

              this.inProgress = false;
              this.isDone = true;
              this.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(false);

            this.msg.hasError = true;
            this.msg.errorMessage =
              err?.message + " tente novamente ou atualize a página.";
          });
      }
    },
    resetFields(status) {
      // reset data values
      this.msg.hasError = !status;
      this.isDisabled = status;
      this.inProgress = status;
      this.isDone = !status;
      this.card.header = this.msg.initHeader;
      this.card.description = this.msg.waitResponse;
    },

    copyTextToClipboard() {
      navigator.clipboard.writeText(this.card.description).then(
        function () {
          alert("A cópia para a área de transferência foi bem-sucedida");
        },
        function (err) {
          alert("Não foi possível copiar o texto: ", err);
        }
      );
    },
  },
};
</script>

<style>
.blog-page {
  margin-top: 70px;
}

.blog-page .blog-title .sub-title {
  font-size: 18px;
}

.blog-page .blog-form {
  margin-top: 30px;
}

.blog-page .blog-form #input-group-1 label {
  font-size: 17px;
  font-weight: 500;
}
</style>