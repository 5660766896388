<template>
  <!-- blog page -->
  <div class="blog-page">
    <!-- blog title -->
    <div class="blog-title d-flex">
      <div class="icon mt-4 mr-1">
        <b-icon icon="file-earmark-post" scale="4" variant="success"></b-icon>
      </div>
      <div class="title-content ml-4">
        <h1 class="title">
          Crie conteúdos, artigos para seu blog e ideias fáceis com apenas um clique
        </h1>
        <div class="sub-title">
          <p>Gere hashtags, títulos, descrições para blogs, palavras-chave e muito mais, basta digitar o assunto ou tags
            para começar.</p>
        </div>
      </div>
    </div>
    <!-- blog form -->
    <div class="blog-form" v-if="show">
      <!-- generate topics -->
      <div class="generate-topics">
        <div class="row">
          <!-- left topic  -->
          <div class="col-md-6">
            <div class="topic-title">
              <b-form-group id="input-group-1"
                label="Tópico do tema  (Por exemplo: Tendências de marketing digital para praticar em 2023)"
                label-for="input-1"
                description="Insira o máximo de informações possíveis, para obter o resultado mais preciso.">
                <b-form-input id="input-1" v-model="topics.topicValue" type="text" :disabled="topics.isDisabled"
                  placeholder="Seja o mais específico possível, não escreva menos de 10 caracteres "
                  required></b-form-input>
              </b-form-group>

              <b-button @click="getTopics" variant="primary"
                :class="{ 'disabled': !topics.topicValue || topics.isDisabled }"
                :disabled="topics.topicValue == null || topics.isDisabled">
                <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': topics.inProgress }"></b-icon>
                Obter sugestões da IA
              </b-button>
            </div>
          </div>

          <!-- right topic -->
          <div class="col-md-6">
            <b-form-textarea id="textarea" v-model="topics.content" placeholder=".... Aguardando resposta " rows="5"
              max-rows="15" disabled></b-form-textarea>
          </div>
        </div>
      </div>

      <hr class="mt-4 mb-5">

      <!-- generate blog content ... -->
      <div class="blog-content" v-if="topics.isDone">
        <div class="form">
          <!-- start form group -->
          <b-form-group id="input-group-1" label="Qual conteúdo você está interessado em obter mais sugestões? "
            label-for="input-1"
            description="Insira o máximo de informações possíveis, para obter o resultado mais preciso.">
            <!-- start form select -->
            <b-form-select v-model="selectedTopic" :options="topics.topicsList" :disabled="blog.isDisabled" size="md"
              class="mb-3">
              <template #first>
                <b-form-select-option value="" disabled>-- Selecione um tópico --</b-form-select-option>
              </template>
            </b-form-select>

            <!-- form input -->
            <b-form-input id="input-1" v-model="blog.inputValue" type="text" :disabled="blog.isDisabled"
              placeholder="Por exemplo: preço do iphone 12, decoração de casa, saúde e bem estar, autocuidado  " required>
            </b-form-input>
          </b-form-group>

          <!-- start button get description -->
          <b-button @click="getBlogContent" variant="success"
            :class="{ 'disabled': !(blog.inputValue && selectedTopic) || blog.isDisabled }"
            :disabled="blog.inputValue == null || blog.isDisabled">
            <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': blog.inProgress }"></b-icon>
            Obter sugestões da IA
          </b-button>
        </div>

        <!-- blog form result -->
        <div class="blog-form-result my-5">
          <div class="card-deck">
            <div class="card">
              <h6 :class="{ 'card-header': true, 'bg-black': !blog.isDone, 'bg-success text-white': blog.isDone }">{{
                blog.header }}</h6>
              <div class="card-body p-0">
                <b-form-textarea id="textarea" v-model="blog.content" placeholder=".... Aguardando resposta " rows="2"
                  max-rows="15" disabled></b-form-textarea>

                <button class="btn btn-success btn-sm mt-3 mx-2 mb-3" v-if="blog.isDone" @click="copyTextToClipboard">
                  <b-icon icon="file-earmark-post"></b-icon>
                  Copiar para área de transferência
                </button>
              </div>
              <div class="card-footer" v-if="blog.inProgress">
                Por favor espere
                <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': blog.inProgress }"></b-icon>
              </div>
            </div>
          </div>

          <!-- errors -->
          <b-alert :show="blog.msg.hasError" dismissible variant="danger" class="mt-3">
            <p>{{ blog.msg.errorMessage }}</p>
          </b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      show: true,

      // topics
      selectedTopic: "",
      topics: {
        topicValue: null,
        content: "Você ainda não pesquisou ^-^",
        isDisabled: false,
        inProgress: false,
        isDone: false,
        msg: {
          waitResponse: ".... Aguardando resposta ",
          initHeader: "A resposta da IA será mostrada aqui",
          doneResponse: "Ai Blog Description Suggestions For : ",
          errorMessage: "",
          hasError: false,
        },
        topicsList: [
          { value: "description with 8000 words", text: "Descrição" },
          { value: "conclusion", text: "Conclusão" },
          { value: "outline", text: "Índice" },
          { value: "content", text: "Conteúdo" },
          { value: "title", text: "Título" },
          { value: "tags", text: "Hashtags" },
          { value: "seo keywords", text: "Palavras Chave seo" }
        ],
      },

      // blog object
      blog: {
        topic: null,
        header: "A resposta da IA será mostrada aqui",
        title: null,
        content: "Você ainda não pesquisou ^-^",
        isDisabled: false,
        inputValue: null,
        inProgress: false,
        isDone: false,
        msg: {
          waitResponse: ".... Aguardando resposta ",
          initHeader: "A resposta da IA será mostrada aqui",
          doneResponse: null,
          errorMessage: "",
          hasError: false,
        },
      },

    };
  },
  methods: {
    getTopics() {
      // if input has value
      if (this.topics.topicValue) {
        // reset data values
        this.resetFields(this.topics, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });

        const params = {
          model: "text-davinci-003",
          prompt: `Write 20 blog subject suggestions list in portuguese language about : ${this.topics.topicValue}`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let topicsList = result.data.choices[0].text;
              topicsList = topicsList.replace(/^\n+/, "").replaceAll('\n', "\n\n");

              this.topics.content = topicsList;
              this.topics.inProgress = false;
              this.topics.isDone = true;
              this.topics.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.topics, false);

            this.topics.msg.hasError = true;
            this.topics.msg.errorMessage =
              err?.message + " tente novamente ou atualize a página.";
          });
      }
    },
    getBlogContent(e) {
      e.preventDefault();

      // if input has value
      if (this.blog.inputValue) {
        // reset data values
        this.resetFields(this.blog, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });

        const params = {
          model: "text-davinci-003",
          prompt: `Write large ${this.selectedTopic} for blog post in portuguese language about : ${this.blog.inputValue}`,
          temperature: 1,
          max_tokens: 2048,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              this.blog.msg.doneResponse = `Sugestões de Blog Ai Para : `;
              this.blog.header = `${this.blog['msg'].doneResponse} ${this.blog.inputValue}`;

              this.blog.title = this.blog.inputValue;

              let blogContent = result.data.choices[0].text;
              blogContent = blogContent.replace(/^\n+/, "");

              this.blog.content = blogContent;

              this.blog.inProgress = false;
              this.blog.isDone = true;
              this.blog.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.blog, false);

            this.blog['msg'].hasError = true;
            this.blog['msg'].errorMessage =
              err?.message + " tente novamente ou atualize a página.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object['msg'].hasError = !status;
      object['isDisabled'] = status;
      object['inProgress'] = status;
      object['isDone'] = !status;
      object['header'] = object['msg'].initHeader;
      object['content'] = object['msg'].waitResponse;
    },

    copyTextToClipboard() {
      navigator.clipboard.writeText(this.blog.content).then(
        function () {
          alert("A cópia para a área de transferência foi bem-sucedida");
        },
        function (err) {
          alert("Não foi possível copiar o texto: ", err);
        }
      );
    },
  },
};
</script>

<style>
.blog-page {
  margin-top: 70px;
}

.blog-page .blog-title .title-content .title {
  font-size: 30px;
}

.blog-page .blog-title .sub-title {
  font-size: 18px;
}

.blog-page .blog-form {
  margin-top: 30px;
}

.blog-page .blog-form #input-group-1 label {
  font-size: 17px;
  font-weight: 500;
}
</style>