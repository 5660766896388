<template>
    <div>
        <div class="card text-white home-card-header">
            <div class="position-relative">
                <img src="https://www.sify.com/wp-content/uploads/2023/01/ChatGPT_ss.jpg" alt="Card Image" class="card-img">
                <div class="card-body-overlay"></div>
                <div class="card-body card-img-overlay">
                    <h1 class="card-title">Crie sua própria IA</h1>
                    <h6 class="card-subtitle pb-2">
                        Obtenha uma ferramenta poderosa para criar aplicativos envolventes com o ChatGPT hoje!
                    </h6>
                    <p class="card-text">
                        Se você está procurando uma maneira incrível de criar aplicativos de sucesso, precisa conhecer a
                        nova ferramenta revolucionária que está transformando o mercado. Com o ChatGPT, você pode criar
                        aplicativos inovadores com um mecanismo de conversação orientado por IA, que torna a interação com o
                        usuário mais natural e envolvente do que nunca. Não perca mais tempo com métodos tradicionais e
                        ultrapassados - adote a ferramenta poderosa do ChatGPT e crie aplicativos que realmente fazem a
                        diferença. Experimente agora mesmo e transforme suas ideias em realidade!
                    </p>
                </div>
            </div>
        </div>

        <my-cards />
    </div>
</template>

<script>
import myCards from "../components/card-choose.vue";
export default {
    components: {
        myCards
    },
    data() {
        return {

        }
    },
}
</script>

<style scoped>
.home-card-header .card-title {
    font-size: 49px;
    text-transform: uppercase;
}

.home-card-header .card-img-overlay {
    margin-top: 50px;
}

.home-card-header .card-subtitle {
    text-decoration: underline;
    color: #ddd;
    text-transform: capitalize;
}

.home-card-header .card-body-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 12, 0.527);
}

.home-card-header .card-text {
    font-size: 19px;
    text-transform: capitalize;
    margin-top: 16px;
    width: 70%;
    font-weight: 300;
    line-height: 1.68;
}</style>