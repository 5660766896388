import Vue from "vue";
import App from "./App.vue";
// export routes array from router folder
import router from "./router";

// import bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// import axios
import axios from "axios";

const base = axios.create({
  baseURL: "http://localhost:5000/api",
});

Vue.prototype.$axios = base;

// import nice scroll
import 'jquery.nicescroll'

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
