<template>
  <!-- blog page -->
  <div class="blog-page">
    <!-- blog title -->
    <div class="blog-title">
      <div class="title-content">
        <h1 class="title">
          Crie postagens profissionais para as suas mídias sociais com IA
        </h1>
        <div class="sub-title">
          <p>
            Não perca mais tempo tentando criar postagens envolventes nas redes sociais! Com o nosso Serviço Gerador de
            Postagem de Mídia Social com Inteligência Artificial, você pode criar conteúdo de alta qualidade em questão de
            segundos, com apenas alguns cliques. Nossos algoritmos de IA foram desenvolvidos para gerar postagens que vão
            impulsionar o alcance da sua marca e engajar seus seguidores de forma incrível. Não deixe que seus
            concorrentes saiam na frente, experimente agora mesmo nosso serviço e veja os resultados surpreendentes!
          </p>
        </div>
      </div>
      <div class="icon mt-4 mb-5 d-flex flex-row align-items-center">
        <b-icon icon="facebook" scale="2" variant="primary" class="svg-icon"></b-icon>
        <b-icon icon="instagram" scale="2" variant="danger" class="svg-icon"></b-icon>
        <b-icon icon="twitter" scale="2" variant="primary" class="svg-icon"></b-icon>
        <b-icon icon="linkedin" scale="2" variant="dark" class="svg-icon"></b-icon>
      </div>
    </div>
    <!-- blog form -->
    <div class="blog-form">
      <b-form @submit="onSubmit" v-if="show" method="post">
        <b-form-group id="input-group-1" label="Quais tópicos de conteúdo você gostaria que sua postagem abordasse?"
          label-for="input-1"
          description="Insira o máximo de informações possíveis, para obter o resultado mais preciso. ">

          <!-- post description -->
          <b-form-input id="input-1" v-model="inputValue" type="text" :disabled="isDisabled"
            placeholder="Por exemplo: tecnologia, negócios, uma incrível aventura na Amazônia" required></b-form-input>

        </b-form-group>

        <!-- social media list -->
        <b-form-select v-model="selectedSocialMedia" :options="socialMediaList" class="mb-3">
          <template #first>
            <b-form-select-option value="" disabled>-- Selecione uma mídia --</b-form-select-option>
          </template>
        </b-form-select>

        <b-button type="submit" variant="primary"
          :class="{ 'disabled': (inputValue == '' || selectedSocialMedia == '') || isDisabled }"
          :disabled="(inputValue == '' || selectedSocialMedia == '') || isDisabled">
          <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': inProgress }"></b-icon>
          Obter sugestões da IA
        </b-button>
      </b-form>

      <!-- blog form result -->
      <div class="blog-form-result mt-5">
        <div class="row">
          <div class="col-md-8">
            <div class="card-deck">
              <div class="card">
                <h4 :class="{ 'card-header': true, 'bg-black': !isDone, 'bg-primary text-white': isDone }">{{ post.header
                }}
                </h4>
                <div class="card-body  p-0">
                  <b-form-textarea id="textarea" v-model="post.description" placeholder=".... Aguardando resposta "
                    rows="2" max-rows="15" disabled></b-form-textarea>

                  <button class="btn btn-primary btn-sm  mt-3 mx-2 mb-3" v-if="isDone" @click="copyTextToClipboard">
                    <b-icon icon="file-earmark-post"></b-icon>
                    Copiar para área de transferência
                  </button>
                </div>
                <div class="card-footer" v-if="inProgress">
                  Por favor, aguardeit
                  <b-icon icon="arrow-clockwise" :class="{ 'b-icon-animation-spin': inProgress }"></b-icon>
                </div>
              </div>
            </div>

            <!-- errors -->
            <b-alert :show="msg.hasError" dismissible variant="primary" class="mt-3">
              <p>{{ msg.errorMessage }}</p>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      selectedSocialMedia: "",
      socialMediaList: [
        { value: "facebook", text: "facebook" },
        { value: "instagram", text: "instagram" },
        { value: "twitter", text: "twitter" },
        { value: "linkedin", text: "linkedin" }
      ],
      show: true,
      isDisabled: false,
      inputValue: null,
      inProgress: false,
      isDone: false,
      msg: {
        waitResponse: ".... Aguardando resposta ",
        initHeader: "A resposta da IA será mostrada aqui",
        doneResponse: "",
        errorMessage: "",
        hasError: false,
      },
      post: {
        header: "A resposta da IA será mostrada aqui",
        title: null,
        description: "Você ainda não pesquisou ^-^",
      },
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      // if input has value
      if (this.inputValue) {
        // reset data values
        this.resetFields(true);

        // create new openAi request
        const client = this.$axios.create({
          headers: {
            Authorization: `Bearer ${this.API_KEY}`,
          },
        });

        const params = {
          model: "text-davinci-003",
          prompt: `escreva uma postagem de mídia social profissional e exclusiva no ${this.selectedSocialMedia}  sobre : ${this.inputValue}`,
          temperature: 1,
          max_tokens: 2048,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              this.post.header = `Ai "${this.selectedSocialMedia} Sugestão de post para : ${this.inputValue}`;
              this.post.title = this.inputValue;

              let socialMediaPost = result.data.choices[0].text;
              socialMediaPost = socialMediaPost.replace(/^\n+/, "");

              this.post.description = socialMediaPost;

              this.inProgress = false;
              this.isDone = true;
              this.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(false);

            this.msg.hasError = true;
            this.msg.errorMessage =
              err?.message + " tente novamente ou atualize a página.";
          });
      }
    },
    resetFields(status) {
      // reset data values
      this.msg.hasError = !status;
      this.isDisabled = status;
      this.inProgress = status;
      this.isDone = !status;
      this.post.header = this.msg.initHeader;
      this.post.description = this.msg.waitResponse;
    },

    copyTextToClipboard() {
      navigator.clipboard.writeText(this.post.description).then(
        function () {
          alert("A cópia para a área de transferência foi bem-sucedida");
        },
        function (err) {
          alert("Não foi possível copiar o texto: ", err);
        }
      );
    },
  },
};
</script>

<style>
.blog-page {
  margin-top: 70px;
}

.blog-page .blog-title .sub-title {
  font-size: 18px;
}

.blog-page .blog-form {
  margin-top: 30px;
}

.blog-page .blog-form #input-group-1 label {
  font-size: 17px;
  font-weight: 500;
}

.icon .svg-icon {
  margin-right: 35px
}
</style>